import { getForumSectionUrl } from '../services/get-section-url';
import createAction from '../services/create-action';
import { transformWixCodeLocation } from '../store/location/transform-wix-code-location';
import { isExperimentEnabled } from '../../app/selectors/experiments-selectors';
import { EXPERIMENT_URL_CUSTOMIZATION } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { buildCustomizedUrl } from '../../app/services/build-customized-url';

export const NAVIGATE_WITHIN_FORUM = 'app/NAVIGATE_WITHIN_FORUM';

const navigateWithinForumAction = createAction(NAVIGATE_WITHIN_FORUM);

export const navigateWithinForum = path => async (dispatch, getState, { wixCodeApi }) => {
  const state = getState();
  const isUrlCustomizationEnabled = isExperimentEnabled(state, EXPERIMENT_URL_CUSTOMIZATION);
  let customPath;
  if (isUrlCustomizationEnabled) {
    customPath = buildCustomizedUrl(state, path, false);
  }

  const sectionUrl = getForumSectionUrl(wixCodeApi);

  // Replace full url with forum relative one
  if (path.startsWith(sectionUrl)) {
    path = path.replace(sectionUrl, '');
  }

  const prevPath = (await transformWixCodeLocation(wixCodeApi, sectionUrl)).pathname;
  dispatch(
    navigateWithinForumAction({
      path: customPath || path,
      prevPath,
    }),
  );

  const pageUrl = wixCodeApi.location.baseUrl;
  const sectionPath = sectionUrl.replace(pageUrl, '');
  return wixCodeApi.location.to(customPath || `${sectionPath}${path}`);
};
